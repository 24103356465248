import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";
import BikeModel from "../Pages/AdminSide/AddModel/BikeModel";
import Edit from "../Pages/AdminSide/Dashboard/Edit";
import Inventory from "../Pages/AdminSide/Dashboard/Index";
import TablePage from "../Pages/AdminSide/Dashboard/TablePage";
import Login from "../Pages/AdminSide/login/Login";
import Contact from "../Pages/contact/Contact";
import Home from "../Pages/Home/Home";
import ItemList from "../Pages/ItemList/ItemList";

const AppRouter = ({ isUser }) => {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Products" element={<ItemList />} />
        <Route path="/login" element={<Login />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/admin" element={isUser ? <Inventory /> : null} />
        <Route  path="/admin/item-table" element={isUser ? <TablePage /> : null} />
        <Route  path="/edit/:id" element={isUser ? <Edit /> : null} />
        <Route  path="/model" element={isUser ? <BikeModel /> : null} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default AppRouter;
