import React, { useState } from "react";
import Button from "../../../Components/Button/Button";
import firebase from "../../../config/firebaseConfig";
import { useSelector } from "react-redux";
import "./bikeM.css";
import Title from "../../../Components/SectionTitle/Title";
import Dialog from "../../../Components/Dialog/Dialog";

const BikeModel = () => {
  const db = firebase.database();
  const [model, setModel] = useState("");
  const handleChange = (e) => {
    setModel(e.target.value);
  };

  const handleAdd = (e) => {
      e.preventDefault()
    db.ref("models")
      .push()
      .set({ model: model.trimStart().trimEnd() })
      .then(() => {
        alert("Successfully added!");
        setModel("");
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const modelSelector = useSelector((State) => {
    return State.itemReducer;
  });
  // console.log(modelSelector.items)

  let [open , setOpen] = useState(false)
  let [modelMsg,setModelMsg] = useState('')
  const handleDelete = (e)=>{
    const isModelUsedFilter = modelSelector.items.filter((val)=> val.model === e.model )
    console.log(isModelUsedFilter)
    if(isModelUsedFilter.length>0){
      // console.log(' used')
      setOpen(true)
      setModelMsg('You have used this model in list!')
    }else{
      // console.log('not used')
      db.ref('models/'+e.key).remove()
      .then(()=>{
        setOpen(true)
        setModelMsg('Successfully deleted!')
      })
    }
  }

  const closeModal = ()=>{
    setOpen(false)
  }

  return (
    <div className="cusContainer">
      <Dialog value={modelMsg} open={open} handleClose={closeModal} />
      <div className="bikeModelContent">
          <Button link linkPath='/admin' value={'Go to Form'} />
        <div className="bkMaddBox">
          <h3>Add New Bike Model</h3>
          <form onSubmit={handleAdd}>
            <input
              required
              type="text"
              placeholder="Enter New Model"
              onChange={handleChange}
              value={model}
            />
            <Button className='mBtn' value="Add New Model" type  />
          </form>
        </div>
        <div className="modelListSec">
            <Title value='Model List' />
          <ul className="ModelList">
            {modelSelector.models
              ? modelSelector.models.map((model, i) => (
                  <li key={i}>
                    <p>{model.model}</p>
                    <Button value={'Delete'} onClick={()=>handleDelete(model)}  />
                  </li>
                ))
              : null}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default BikeModel;
