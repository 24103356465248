import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

import "./brand.css";

// import required modules
import { EffectCoverflow, Pagination ,Autoplay} from "swiper";

function Brands() {
  return (
    <div className="cusContainer">
      <div className="brandContent">
        <Swiper
          effect={"coverflow"}
          grabCursor={true}
          //   centeredSlides={true}
          //   slidesPerView={"auto"}
          slidesPerView={3}
          // centeredSlides={true}
          // autoplay={
          //  {
          //    delay:1000

          //  }
          // }
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          autoplay={true}
          coverflowEffect={{
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
          }}
          //   pagination={true}
          pagination={{
            type: "bullets",
          }}
          breakpoints={{
            270: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 50,
            },
          }}
          modules={[EffectCoverflow, Pagination,Autoplay]}
          className="mySwiper"
        >
          <SwiperSlide>
            <h1 className="dealsText">Nut Bolt</h1>
          </SwiperSlide>
          <SwiperSlide>
          <h1 className="dealsText">Rubber</h1>
          </SwiperSlide>
          <SwiperSlide>
          <h1 className="dealsText">Filter-oil-petrol</h1>
          </SwiperSlide>
          <SwiperSlide>
          <h1 className="dealsText">Jan</h1>
          </SwiperSlide>
          <SwiperSlide>
          <h1 className="dealsText">Plastic</h1>
          </SwiperSlide>
          <SwiperSlide>
          <h1 className="dealsText">Silver</h1>
          </SwiperSlide>
          <SwiperSlide>
          <h1 className="dealsText">Loha</h1>
          </SwiperSlide>
          <SwiperSlide>
          <h1 className="dealsText">Seat cover</h1>
          </SwiperSlide>
          <SwiperSlide>
          <h1 className="dealsText">Rickshaw body parts</h1>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
}

export default Brands;
