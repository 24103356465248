import { useEffect } from "react";
import "./App.css";
import AppRouter from "./Router/Router";
import firebase from "./config/firebaseConfig";
import { useDispatch, useSelector } from "react-redux";
import { setUser,setItems ,setModel} from "./GlobalState/CreateSlice";

function App() {
  const auth = firebase.auth();
  const db = firebase.database()
  const dispatch = useDispatch();
  const selector = useSelector((state) => {
    return state.itemReducer;
  });
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        const userData = user;
        dispatch(setUser(true));
      } else {
        console.log("user not login");
      }
    });
    db.ref('Items').on('value',((snapshot)=>{
      let itemArray =[]
      snapshot.forEach(function(val){
        const getAllData = val.val()
        const itemKey = val.key
        // getAllData.sNo = index;
        getAllData.key = itemKey
        itemArray.push(getAllData)
        // console.log(val.val(),ind)
        // console.log(getAllData)
      })
      dispatch(setItems(itemArray))
    }))
    db.ref('models').on('value',((snap)=>{
      let model = []
      snap.forEach((val)=>{
         const getModel = val.val()
         const modelKey = val.key
         getModel.key = modelKey
         model.push(getModel)
      })
      dispatch(setModel(model))
    }))
  }, []);
  return (
    <div className="App">
      <AppRouter isUser={selector.isUser} />
    </div>
  );
}

export default App;
