import React from "react";
import { Link, NavLink } from "react-router-dom";
import "./btn.css";

function Button({ link, value, linkPath, style, onClick,className ,type}) {
  return (
    <div className="btnContainer">
      {link ? (
        <NavLink to={linkPath} className={`Linkbtn ${className} `}style={style}>
          {value}
        </NavLink>
      ) : (
        <button
          onClick={onClick}
          type={type?'submit':"button"}
          className={`cusBtn ${className}`}
          style={style}
        >
          {value}
        </button>
      )}
    </div>
  );
}

export default Button;
