import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import img1 from '../../assets/125-banner (1).jpg'
import img2 from '../../assets/cd-70.jpg'
import mob2 from '../../assets/125-mobile-banner.jpg'
import mob1 from '../../assets/cd-70-mobile.jpg'
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./imgSlider.css";

// import required modules
import { Pagination, Navigation,Autoplay } from "swiper";

function ImageSlider() {
  return (
    <div className="imgSliderCont">
      <div className="imgSlider">
        <Swiper
          pagination={{
            type: "progressbar",
            currentClass:'colorProgres'
            // type:'bullets'
            
          }}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          navigation={true}
          modules={[Pagination, Navigation,Autoplay]}
          className="mySwiper"
        >
          <SwiperSlide className="desktopV">
            <img
              src={img1}
              alt="..."
            />
          </SwiperSlide>
          <SwiperSlide className="desktopV">
            <img
              src={img2}
              alt=""
            />
          </SwiperSlide>
          <SwiperSlide className="mobV">
            <img
              src={mob1}
              alt="..."
            />
          </SwiperSlide>
          <SwiperSlide className="mobV">
            <img
              src={mob2}
              alt=""
            />
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
}

export default ImageSlider;
