import React from "react";
import "./contact.css";
import { FaInstagram } from "react-icons/fa";
import { BsFacebook, BsFillTelephoneFill } from "react-icons/bs";
import { MdAttachEmail } from "react-icons/md";
import { GiPostOffice } from "react-icons/gi";
import {FiTwitter} from 'react-icons/fi'
import Title from "../../Components/SectionTitle/Title";
import Schedule from "./schedule";
import AnimatedLine from "../../Components/AnimatedLine/AnimatedLine";

const Contact = () => {
  return (
    <>
      <AnimatedLine />

      <div className="cusContainer">
        <Schedule />
        <section id="contact">
          {/* <h1 className="section-header">Contact</h1> */}
          <Title value="Contact Us" />
          <div className="contact-wrapper">
            {/* Left contact page */}
            <form id="contact-form" className="form-horizontal" role="form">
              <div className="form-group">
                <div className="col-sm-12">
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder="NAME"
                    name="name"
                    defaultValue=""
                    required
                  />
                </div>
              </div>
              <div className="form-group">
                <div className="col-sm-12">
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="EMAIL"
                    name="email"
                    defaultValue=""
                    required
                  />
                </div>
              </div>
              <textarea
                className="form-control"
                rows={5}
                placeholder="MESSAGE"
                name="message"
                required
                defaultValue={""}
              />
              <button
                className="cusBtnForm"
                id="submit"
                type="submit"
                // value="SEND"
              >
                {/* <div className="alt-send-button">
                <span className="send-text">SEND</span>
              </div> */}
                Send
              </button>
            </form>
            {/* Left contact page */}
            <div className="direct-contact-container">
              <ul className="contact-list">
                <li className="list-item">
                  <GiPostOffice className="fromIcon" />
                  <span className="contact-text place">
                    Shop No. 18-35, Main Gate Jinnah Colony, Basement Ali
                    Centre, Faislabad
                  </span>
                </li>
                <li className="list-item">
                  <BsFillTelephoneFill className="fromIcon" />
                  <span className="contact-text phone">
                    <a href="tel:041-2633607" title="Give me a call">
                      041-2633607
                    </a>
                  </span>
                </li>
                <li className="list-item">
                  <MdAttachEmail className="fromIcon" />
                  <span className="contact-text gmail">
                    <a
                      href="mailto:asfnaeem@gmail.com"
                      title="Send me an email"
                    >
                      asfnaeem@gmail.com
                    </a>
                  </span>
                </li>
              </ul>
              <hr />
              <div className="followD">
                <h4>Follow Us</h4>
              </div>
              <ul className="social-media-list">
                <li>
                  <a
                    href="https://www.facebook.com/Auto-Star-Faisalabad-105859045365498/"
                    target="_blank"
                    className="contact-icon"
                  >
                    <BsFacebook />
                  </a>
                </li>

                <li>
                  <a href="mailto:asfnaeem@gmail.com" className="contact-icon">
                    <MdAttachEmail />
                  </a>
                </li>
                <li>
                  <a href="https://instagram.com/autostarfaisalabad?utm_medium=copy_link" target="_blank" className="contact-icon">
                    <FaInstagram />
                  </a>
                </li>
                <li>
                  <a
                    className="dribbble"
                    href="https://twitter.com/AutoFaisalabad"
                    target="_blank"
                  >
                    <FiTwitter />
                  </a>
                </li>
              </ul>
              {/* <hr /> */}
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Contact;
