import React from 'react';
import './title.css'

function Title({value}) {
    return (
        <div className='titleContainer'>
            <div className="Title">
                <h1>{value}</h1>
                <hr className='animLine' />
            </div>
        </div>
    );
}

export default Title;