import React, { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Button from '../Button/Button'
import { FiSearch } from 'react-icons/fi'
import { setFilterData } from '../../GlobalState/CreateSlice'
import './table.css'
import logo from '../../assets/asnf-logo.png'
// import { PDFExport, savePDF } from '@progress/kendo-react-pdf'
import firebase from '../../config/firebaseConfig'
import Dialog from '../Dialog/Dialog'
import { Pagination } from 'react-bootstrap'
// import ReactToPdf from 'react-to-pdf'
import ReactToPrint from 'react-to-print'
import Loader from '../Loader'

const Tables = ({ isDashBoard }) => {
  const [search, setSearch] = useState('')
  const dispatch = useDispatch()
  const itemSelector = useSelector((state) => {
    return state.itemReducer
  })
  const { items, models } = itemSelector

  const [itemData, setData] = useState([])
  const handleChangeFIlter = (e) => {
    dispatch(setFilterData(e.target.value))
  }

  useEffect(() => {
    if (items) {
      if (search === '') {
        setData(items)
      }
      // condition if user already type any word in input
      else {
        const filterSearchItem = items.filter((val) => {
          const check1 = val.inputValues.Name.toLowerCase().includes(
            search.toLowerCase(),
          )
          const check = val.inputValues.urduName.includes(search)
          const filterBySNo = val.sNo.toString().includes(search)
          // console.log(filterBy)
          return check1 || check || filterBySNo
        })
        setData(filterSearchItem)
      }
    }
    setActive(1)
  }, [items, search])
  // console.log(items);

  const handleSearch = (e) => {
    const filterSearchItem = items.filter((val) => {
      const check1 = val.inputValues.Name.toLowerCase().includes(
        e.target.value.toLowerCase(),
      )
      const check = val.inputValues.urduName.includes(e.target.value)
      const filterBySNo = val.sNo.toString().includes(e.target.value)
      return check1 || check || filterBySNo
    })
    setData(filterSearchItem)
    setSearch(e.target.value)
  }

  const componentRef = useRef()
  const pdfExportComponent = React.useRef(null)

  // const exportPDFWithComponent = () => {
  //   if (pdfExportComponent.current) {
  //     pdfExportComponent.current.save()
  //   }
  // }

  let [open, setOpen] = useState(false)
  let [modalMsg, setModalMsg] = useState('')
  const db = firebase.database()
  const handleDelte = (e) => {
    db.ref('Items/' + e)
      .remove()
      .then(() => {
        console.log('deleted')
        setOpen(true)
        setModalMsg('Item Successfully deleted!')
      })
      .catch(() => {
        setOpen(true)
        setModalMsg('Please check your internet connection')
      })
  }
  const closeModal = () => {
    setOpen(false)
  }

  const dataLimit = 26
  let [active, setActive] = useState(1)
  const divide = Math.ceil(itemData.length / dataLimit)
  let newItemsData = []
  // console.log(divide)

  for (let number = 1; number <= 5; number++) {
    newItemsData.push(
      <Pagination.Item
        key={number}
        active={number === active}
        onClick={changePage}
        className="paginItem"
      >
        {number}
      </Pagination.Item>,
    )
  }

  function changePage(event) {
    // const pageNumber = Number(event.target.textContent)
    // const pageNumber =
    if (active < divide) {
      setActive(active + 1)
    }
  }

  const handleBack = () => {
    if (active > 1) {
      setActive(active - 1)
    }
  }

  const getPaginatedData = () => {
    const startIndex = active * dataLimit - dataLimit
    const endIndex = startIndex + dataLimit
    // console.log(startIndex, endIndex)
    return itemData.slice(startIndex, endIndex)
  }

  let [load, setLoad] = useState(false)

  // render table row for pdf
  let returnTableForPrint = () => {
    //  <td style="border: 1px solid #007670;padding: 15px;">${val.email}</td>
    let tableRow = itemData.map(
      (val, i) =>
        `<tr key=${val.id}>
          <td style="border: 1px solid ;padding: 15px; text-align:center;">${
            i + 1
          }</td>
          <td style="border: 1px solid ;padding: 15px; text-align:center;">${
            val.model
          }</td>
          <td style="border: 1px solid ;padding: 15px; text-align:center;">${
            val.inputValues?.Name
          }</td>
          <td style="border: 1px solid ;padding: 15px; text-align:center;">${
            val.inputValues.urduName
          }</td>
          <td style="border: 1px solid ;padding: 15px; text-align:center;">${
            val.inputValues?.qty
          }</td>
          <td style="border: 1px solid ;padding: 15px; text-align:center;">${
            val.inputValues?.cp
          }</td>
        </tr>`,
    )
    return tableRow
  }

  // generate pdf
  const handleExport = () => {
    // <th style="border: 1px solid #007670;padding: 15px;">Emails</th></tr>
    let openForPrint = window.open('', '', 'height=1000, width=1000')
    openForPrint.document.write('<html>')
    openForPrint.document.write(`<body>
    <div className="printHead" style="display:flex; justify-content:space-between; align-item:end;">
            <img style="width:150px;" src=${logo} alt="..." />
            <h1>Auto Star Faislabad</h1>
          </div>
      <table style='border-collapse: collapse;width :100%;margin-top:20px;'>
      <thead>
      <tr>
      <th style=" width: 10%; border: 1px solid ;padding: 15px; text-align:center;">S.No</th>
      <th style=" width: 20%; border: 1px solid ;padding: 15px; text-align:center;">Model</th>
      <th style=" width: 30%;border: 1px solid ;padding: 15px; text-align:center; ">Description</th>
      <th style= "width: 30% ;border: 1px solid ;padding: 15px; text-align:center;">نام</th>
      <th style= "width: 5% ;border: 1px solid ;padding: 15px; text-align:center;">Qty</th>
      <th style=" width: 10%; border: 1px solid ;padding: 15px; text-align:center;">CP</th>
      </thead>
      <tbody>
       ${returnTableForPrint().join('')} </tbody></table>`)
    openForPrint.document.write('</body></html>')
    openForPrint.document.close()
    openForPrint.print()
  }

  return (
    <div className="cusTableContainer">
      <Dialog open={open} handleClose={closeModal} value={modalMsg} />
      {load ? <Loader /> : null}
      <div className="filterSection">
        <div className="searchBar">
          <input
            type="text"
            placeholder="Search with name"
            onChange={(e) => handleSearch(e)}
            value={search}
          />
          <FiSearch className="srIcon" />
        </div>
        <div className="selectFilter">
          <select name="" id="" onChange={handleChangeFIlter}>
            <option value="All">All</option>
            {models.map((val, i) => {
              return (
                <option key={i} value={val.model}>
                  {val.model}
                </option>
              )
            })}
          </select>
        </div>
      </div>
      <button onClick={handleExport} className="prntBtn">
        Print this out
      </button>
      {/* print table  */}
      {/* <ReactToPrint
        onBeforeGetContent={() => setLoad(true)}
        onAfterPrint={() => setLoad(false)}
        trigger={() => <button className="prntBtn">Print this out</button>}
        content={() => componentRef.current}
      /> */}
      {/* <div className="printTable">
        <div className="printer" id="printTable" ref={componentRef}>
          <div className="printHead">
            <img src={logo} alt="..." />
            <h1>Auto Star Faislabad</h1>
          </div>
          <table className="pdfTable">
            <thead>
              <tr>
                <th style={{ width: '10%' }}>S.No</th>
                <th style={{ width: '20%' }}>Model</th>
                <th style={{ width: '30%' }}>Description</th>
                <th style={{ width: '30%' }}>نام</th>
                <th style={{ width: '5%' }}>Qty</th>
                <th style={{ width: '10%' }}>CP</th>
              </tr>
            </thead>
            <tbody>
              {itemData &&
                itemData.map((val, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{val.model}</td>
                    <td>{val.inputValues?.Name}</td>
                    <td>{val.inputValues.urduName}</td>
                    <td>{val.inputValues?.qty}</td>
                    <td>{val.inputValues?.cp}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div> */}
      <br />
      {/* ========================================= */}

      {/* old */}
      <div className="itemTable">
        <div className={!isDashBoard ? 'pItems' : 'dbTableSect pItems'}>
          <table className="itemTable">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Model</th>
                <th>Description</th>
                <th>نام</th>
                <th>Qty</th>
                <th>CP</th>
                {isDashBoard ? <th></th> : null}
              </tr>
            </thead>
            <tbody>
              {itemData.length ? (
                getPaginatedData().map((val, i) => (
                  <tr key={i}>
                    <td>{val.sNo}</td>
                    <td>{val.model}</td>
                    <td>{val.inputValues?.Name}</td>
                    <td>{val.inputValues.urduName}</td>
                    <td>{val.inputValues.qty}</td>
                    <td>{val.inputValues?.cp}</td>
                    {isDashBoard ? (
                      <td>
                        <Button
                          value="Edit"
                          link
                          linkPath={`/edit/${val.key}`}
                          style={{ margin: '0px', color: 'red' }}
                        />
                        <Button
                          value="Delete"
                          // link
                          onClick={() => handleDelte(val.key)}
                          style={{
                            margin: '0px',
                            color: 'red',
                            marginTop: '10px',
                          }}
                        />
                      </td>
                    ) : null}
                  </tr>
                  // </div>
                ))
              ) : (
                <tr>
                  <td className="emptyTb"></td>
                  <td className="emptyTb"></td>
                  <td className="emptyTb">No items </td>
                  <td className="emptyTb"></td>
                  <td className="emptyTb"></td>
                  <td className="emptyTb"></td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <Pagination className="justify-content-center mt-3">
          <Pagination.Prev onClick={handleBack} />
          {/* {newItemsData} */}
          <p className="pageNum">
            Page : {active} / {divide}
          </p>
          <Pagination.Next onClick={changePage} />
        </Pagination>
      </div>
      {/*  */}
    </div>
  )
}

export default Tables
