import React from "react";
import Button from "../Button/Button";
import card1 from "../../assets/1.jpg";
import card2 from "../../assets/2.jpg";
import card3 from "../../assets/3.jpg";
import card4 from "../../assets/4.jpg";
import card5 from "../../assets/5.jpg";
import card6 from "../../assets/6.jpg";
import card7 from "../../assets/7.jpg";
import card8 from "../../assets/8.jpg";
import "./card.css";

const cardData = [
  { img: card1, name: "Sprocket set rickshaw" },
  { img: card2, name: "Spring" },
  { img: card3, name: "Body Parts" },
  { img: card4, name: "Plastic Parts" },
  { img: card5, name: "Sprocket set H70" },
  { img: card6, name: "Rubber Parts" },
  { img: card7, name: "Nut Bolt" },
  { img: card8, name: "Jan" },
];

function Card(props) {
  return (
    <div className="cusContainer">
      <div className="cardContent">
        {cardData.map(
          (val, i) => (
            <div className="cusCard" key={i}>
              <img
                src={val.img}
                // src="https://image.shutterstock.com/image-vector/vector-motorcycle-spares-concept-260nw-341409191.jpg"
                alt="..."
              />
              <div className="cardBody">
                <h3 className="h3Text">{val.name}</h3>
                {/* <p className="paraText">Model : CD70F</p> */}
                {/* <p className="paraText">RS:150</p> */}
              </div>
            </div>
          )
        )}
      </div>
      <div className="crdBtn">
        <Button value="Show More" className="shBtn" link linkPath="/Products" />
      </div>
    </div>
  );
}

export default Card;
