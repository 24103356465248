import React from 'react'

const AnimatedLine = () => {
  return (
    <div className='mrqueText'>
        <marquee > برانڈ کی تمام ورائٹی دستیاب ھے  ASNF ، موٹر سائیکل،رکشہ  پاٹس</marquee>
        {/* <marquee behavior="" direction=""></marquee> */}
    </div>
  )
}

export default AnimatedLine