import { createSlice } from "@reduxjs/toolkit";
import { InitialState } from "./InitialState";

const itemSlice = createSlice({
  name: "items",
  initialState: InitialState,
  reducers: {
    setUser: (state, action) => {
      state.isUser = action.payload;
    },
    setItems: (state, action) => {
      state.filtersData = action.payload;
      let addSno = state.filtersData.filter((val,i)=>{
        val.sNo = i + 1;
        return val
      })
      state.items = addSno;
    },
    setFilterData: (state, action) => {
      // console.log(action.payload)
      const filterData = state.filtersData.filter((items) =>
        action.payload !== "All"
          ? items.model.toLowerCase() === action.payload.toLowerCase()
          : state.items
      );
      state.items = filterData;
    },
    setModel:(state,action)=>{
      state.models = action.payload
    }
  },
});

export const { setUser, setItems, setFilterData ,setModel} = itemSlice.actions;
export const itemReducer = itemSlice.reducer;
