import { useState, useEffect } from 'react'
import AddItem from './Form'
// import Inventorytable from "./Table";
import firebase from '../../../config/firebaseConfig'

import { useNavigate } from 'react-router-dom'
// import "./inventory.css";
import { useSelector } from 'react-redux'
import './dashboard.css'
import Button from '../../../Components/Button/Button'
import Loader from '../../../Components/Loader'

//  initialized form fields
const itemInput = {
  Name: '',
  model: '',
  urduName: '',
  qty: "",
  cp: '',
  // price: "",
  // run: "",
  // foc: "",
  // ctnQty: "",
}

const Inventory = ({ id }) => {
  const navigate = useNavigate()
  // All Others form Fields state
  const [inputValues, setInputValues] = useState(itemInput)

  // loading
  let [loading, setLoading] = useState(false)

  // get All products data from Redux
  const itemSelector = useSelector((state) => {
    return state.itemReducer.items
  })
  let [editItemId, setEditItemId] = useState('')

  // get prev detail  of selected item to edit/update
  useEffect(() => {
    let itemFilter = itemSelector.filter((val) => val.key === id)
    // setItemFilter(itemFilter)
    if (itemFilter.length) {
      setInputValues(itemFilter[0].inputValues)
      setEditItemId(itemFilter[0].key)
    }
    // clean up function
    return () => {
      setInputValues(itemInput)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // handle inputs change
  const handleItemsInp = (e) => {
    let { name, value } = e.target
    setInputValues({ ...inputValues, [name]: value })
  }

  // firebase database reference
  const db = firebase.database()
  const itemsRef = db.ref(`Items`)
  let [isSuccess,setLoad] = useState(false)
  // store Product form data  in firebase Database
  const handleAddAndUpdateItem = (e) => {
    e.preventDefault()
    setLoading(true)
    setLoad(true)
    // update or edit function
    if (id) {
      db.ref(`Items/${id}`)
        .update({
          inputValues,
          model: inputValues.model.trimStart().trimEnd(),
        })
        .then(() => {
          setLoading(false)
          navigate('/admin/item-table')
          //   history.push(`/dashboard`);
          setInputValues(itemInput)
          setLoad(false)
        })
        .catch((err) => {
          console.log(err)
          setLoading(false)
          setLoad(false)
        })
    }
    // // add new item function
    else {
      itemsRef
        .push()
        .set({
          inputValues,
          model: inputValues.model.trimStart().trimEnd(),
        })
        .then(() => {
          setLoading(false)
          console.log('added')
          navigate('/admin/item-table')
          setInputValues(itemInput)
          setLoad(false)
        })
        .catch((err) => {
          console.log(err)
          setLoading(false)
          setLoad(false)
        })
    }
  }

  // go Back
  // const handleBackTable = () => {
  //   history.push("/dashboard");
  //   setForm(false);
  // };

  return (
    <div className="inventoryContainer">
      {!isSuccess ? null : <Loader />}
      <br />
      <div className="dsBtn">
        <Button link linkPath="/admin/item-table" value="Go To Table" />
        <Button link linkPath="/model" value="Add New Model" />
      </div>
      <div className="inventtable">
        <AddItem
          handleAddItem={handleAddAndUpdateItem}
          handleItemsInp={handleItemsInp}
          inputValues={inputValues}
          loading={loading}
          // backTable={handleBackTable}
          cross
          id={id}
        />
      </div>
    </div>
  )
}

export default Inventory
