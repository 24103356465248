import React from 'react'
import { Link } from 'react-router-dom'
import { IoMdArrowRoundBack } from 'react-icons/io'
import { MdAccountTree, MdOutlineAddAPhoto } from 'react-icons/md'
import { GoVerified } from 'react-icons/go'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { itemReducer } from '../../../GlobalState/CreateSlice'

const AddItem = ({
  handleAddItem,
  handleItemsInp,
  handleWatchImg,
  handlePdfSelect,
  inputValues,
  loading,
  cross,
  backTable,
  // form,
  backLink,
  editForm,
  id,
}) => {
  const modelSelector = useSelector((state) => {
    return state.itemReducer.models
  })

  return (
    <div className="form_box">
      <div className="form_content">
        {/* {backLink ? (
          <Link to="/dashboard" className="icBtn">
            <IoMdArrowRoundBack />
          </Link>
        ) : (
          <button className="icBtn" onClick={backTable}>
            <IoMdArrowRoundBack />
          </button>
        )} */}
        <form onSubmit={handleAddItem}>
          <div className="itemForm">
            <div className="formMd">
              <p
                style={{
                  marginBottom: '10px',
                  marginTop: '20px',
                  fontWeight: 'bold',
                  textAlign: 'center',
                  fontSize: '1.5rem',
                }}
              >
                Item Information
              </p>
              <div className="item_inp">
                <label htmlFor="eventname">
                  <p>Item Name:</p>
                  <input
                    type="text"
                    required
                    onChange={handleItemsInp}
                    name="Name"
                    id="Name"
                    value={inputValues.Name}
                  />
                </label>
              </div>
              <div className="item_inp inpUrdu">
                <label htmlFor="">
                  <p> آ ئٹم نام </p>
                  <input
                    type="text"
                    name="urduName"
                    required
                    onChange={handleItemsInp}
                    value={inputValues.urduName}
                  />
                </label>
              </div>
              <div className="item_inp ">
                <label htmlFor="dificulty">
                  <p>Model</p>
                  <select
                    onChange={handleItemsInp}
                    name="model"
                    id="model"
                    required
                  >
                    <option value={inputValues.model}>{inputValues.model}</option>
                    {modelSelector &&
                      modelSelector.map((model, i) => (
                        <option key={i} value={model.model}>
                          {model.model}
                        </option>
                      ))}
                  </select>
                </label>
              </div>

              <div className="item_inp">
                <label htmlFor="">
                  <p>Quantity: </p>
                  <input
                    type="text"
                    name="qty"
                    required
                    onChange={handleItemsInp}
                    value={inputValues.qty}
                  />
                </label>
              </div>
              <div className="item_inp">
                <label htmlFor="">
                  <p> CP:</p>
                  <input
                    type="text"
                    name="cp"
                    required
                    onChange={handleItemsInp}
                    value={inputValues.cp}
                  />
                </label>
              </div>
              {/* <div className="item_inp">
                <label htmlFor="">
                  <p> Price:</p>
                  <input
                    type="text"
                    name="price"
                    required
                    onChange={handleItemsInp}
                    value={inputValues.price}
                  />
                </label>
              </div>

              <div className="item_inp">
                <label className="" htmlFor="">
                  <p> Runs:</p>
                  <input
                    type="text"
                    name="run"
                    required
                    onChange={handleItemsInp}
                    value={inputValues.run}
                  />
                </label>
              </div>
              <div className="item_inp">
                <label className="" htmlFor="">
                  <p> FOC:</p>
                  <input
                    type="text"
                    name="foc"
                    required
                    onChange={handleItemsInp}
                    value={inputValues.foc}
                  />
                </label>
              </div>
              <div className="item_inp">
                <label className="" htmlFor="">
                  <p> CTN Qty:</p>
                  <input
                    type="text"
                    name="ctnQty"
                    required
                    onChange={handleItemsInp}
                    value={inputValues.ctnQty}
                  />
                </label>
              </div> */}
            </div>
          </div>
          <br />
          <div className="addItem_btn">
            {id ? (
              <button className="btn csBtn" disabled={loading} type="submit">
                {loading ? 'Loading...' : 'Save'}
              </button>
            ) : (
              <button className="btn csBtn" disabled={loading} type="submit">
                {loading ? 'Loading...' : 'Add Item'}
              </button>
            )}
          </div>
        </form>
      </div>
      {loading ? (
        <div className="addForm_loading">
          <div className="addForm_loading_md">
            {/* <CircularProgress /> */}
            <p>Loading...</p>
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default AddItem
