import React, { useState } from "react";
import { BsTwitter } from "react-icons/bs";
import { FaTelegramPlane } from "react-icons/fa";
import { HiOutlineMenuAlt1 } from "react-icons/hi";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import "./header.css";
import Button from "../Button/Button";
import firebase from "../../config/firebaseConfig";
import { setUser } from "../../GlobalState/CreateSlice";
import logo from "../../assets/asnf-logo.png";
import sLogo from "../../assets/sidebar.png";

const Header = () => {
  const auth = firebase.auth();
  const dispatch = useDispatch();
  let [open, setOpen] = useState(false);
  const handleOpenMenu = () => {
    setOpen(true);
  };
  const handleCloseMenu = () => {
    setOpen(false);
  };

  const selector = useSelector((state) => {
    return state.itemReducer;
  });

  const handleLogout = () => {
    auth.signOut().then(() => {
      dispatch(setUser(false));
    });
  };

  return (
    <div className="headContainer">
      {open ? <div className="overLay" onClick={handleCloseMenu}></div> : null}
      <div className="headMd">
        <div className="headContent">
          <div className="headLogo">
            <NavLink to="/">
              <img src={logo} alt="..." />
            </NavLink>
            <hr />
          </div>
          <div className={open ? "openMenu headLinks" : "closeMenu headLinks"}>
            <ul className="navLinks">
              {["Home", "Products", "Contact"].map((item, i) => (
                <li key={i}>
                  <NavLink
                    to={item === "Home" ? "/" : "/" + item}
                    onClick={handleCloseMenu}
                  >
                    {item}
                  </NavLink>
                </li>
              ))}
              {/* <li>
                <a href="#aboutUs" onClick={handleCloseMenu}>
                  About us
                </a>
              </li> */}
            </ul>
            <img className="sLogo" src={sLogo} alt="..." />
            <p className="mobSideBottm">
              {" "}
              Copyright &copy; {" " + new Date().getFullYear() + " "} All Rights
              Reserved by
              <a href="https://smashcode.dev/" target="_blank" rel="noopener noreferrer"> Smash Code</a>
            </p>
          </div>
          <div className="headSLinks">
            <ul className="sLinks">
              {/* {selector.isUser ? (
                <>
                  <li>
                    <NavLink to="/admin" onClick={handleCloseMenu}>
                      DashBoard
                    </NavLink>
                  </li>
                  <li>
                    <Button onClick={handleLogout} className="btnLink" value='Logout'></Button>
                  </li>
                </>
              ) : (
                <li>
                  <NavLink to="/login" onClick={handleCloseMenu}>
                    Login
                  </NavLink>
                </li>
              )} */}
              {!open ? (
                <li className="menuBtn">
                  <button onClick={handleOpenMenu}>
                    <HiOutlineMenuAlt1 />
                  </button>
                </li>
              ) : (
                <li className="closeBtn">
                  <button onClick={handleCloseMenu}>
                    <span className={open ? "sp1" : ""}></span>
                    <span className={open ? "sp2" : ""}></span>
                  </button>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
