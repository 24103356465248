import React from "react";
import Button from "../../Components/Button/Button";
import Title from "../../Components/SectionTitle/Title";
import Tables from "../../Components/Table/Table";
import "./list.css";

function ItemList() {
  return (
    <div className="cusContainer">
      <div className="listSection">
        <div className="listContent">
          <Title value="ITEM LIST" />
          <Tables />
        </div>
      </div>
    </div>
  );
}

export default ItemList;
