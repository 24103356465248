import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyAMGteN3IBm2Bf0EYJyPwla6q_3OYuLYrA",
  authDomain: "smashcode-asnf.firebaseapp.com",
  projectId: "smashcode-asnf",
  storageBucket: "smashcode-asnf.appspot.com",
  messagingSenderId: "687129933088",
  appId: "1:687129933088:web:a842b1d0114939eef45345"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default firebase;
