import React, { useState } from "react";
import LoginForm from "./loginForm";
import { useHistory,useNavigate } from "react-router-dom";
import firebase from '../../../config/firebaseConfig'
import "./login.css";

const inputFieldValue = {
  email: "",
  password: "",
};

const Login = () => {
  const nevigate = useNavigate();
  let auth = firebase.auth();
  const [loginInputs, setLoginInputs] = useState(inputFieldValue);
  const handleChangeLoginInputs = (event) => {
    setLoginInputs({
      ...loginInputs,
      [event.target.name]: event.target.value,
    });
  };

  const handleLoginSubmit = (event) => {
    event.preventDefault();
    let { email, password } = loginInputs;
    auth
      .signInWithEmailAndPassword(email, password)
      .then((userLogin) => {
        // history.push("/dashboard");
        nevigate('/admin')
      })
      .catch((error) => {
      });
    setLoginInputs(inputFieldValue);
  };

  return (
    <div>
      <LoginForm
        handleChange={handleChangeLoginInputs}
        handleSubmit={handleLoginSubmit}
        inputsValues={loginInputs}
        // loader={loader}
      />
    </div>
  );
};

export default Login;