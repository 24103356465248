import React from 'react'
import {Spinner} from 'react-bootstrap'

const Loader = () => {
  return (
    <div className='loaderContianer'>
        <Spinner animation="grow" className='spinClas' variant="danger" />
    </div>
  )
}

export default Loader