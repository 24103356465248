import React from "react";
import { BsFacebook } from "react-icons/bs";
import { MdAttachEmail } from "react-icons/md";
import { FaInstagram } from "react-icons/fa";
import {FiTwitter} from 'react-icons/fi'
import logo from "../../assets/sidebar.png";
import "./footer.css";
import { NavLink } from "react-router-dom";

function Footer(props) {
  return (
    <div>
      <footer className="site-footer">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-5">
              <img className="fLogo" src={logo} alt="..." />
            </div>
            <div className="col-sm-12 col-md-2 fCompOne">
              <h6>Companies</h6>
              <ul className="footer-links">
                <li>Silver Star</li>
                <li>Ss break shoe</li>
                <li>Doungha</li>
                <li>Vendor filter</li>
                <li>Qingqi</li>
                <li>Goetze</li>
                <li className="mobF">WAH vendor</li>
                <li className="mobF">Local parts</li>
                <li className="mobF">Nok seal </li>
                <li className="mobF">Ss leather</li>
                <li className="mobF">Suzuki</li>
              </ul>
            </div>

            <div className="col-xs-6 col-md-2 fCompTwo">
              <h6></h6>
              <br />
              <ul className="footer-links">
                {/* <li>Silver Star</li>
                <li>Ss break shoe</li>
                <li>Nok seal</li>
                <li>Doungha</li>
                <li>Vendor filter</li>
                <li>Qingqi</li>
                <li>Goetze</li> */}
                <li>WAH vendor</li>
                <li>Local parts</li>
                <li>Nok seal </li>
                <li>Ss leather</li>
                <li>Suzuki</li>
              </ul>
            </div>

            <div className="col-xs-6 col-md-2  mobHide" >
              <h6>Quick Links</h6>
              <ul className="footer-links">
                <li>
                <NavLink  to='/'>
                Home
                </NavLink>

                </li>
                <li>
                <NavLink  to='/Contact'>
                Contact Us
                </NavLink>

                </li>
                <li>
                  <NavLink  to='/Products'>
                    Products
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
          <hr />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-sm-6 col-xs-12">
              <p className="copyright-text">
                Copyright &copy; {" " + new Date().getFullYear() + " "} All
                Rights Reserved by
                <a href="#"> Smash Code</a>.
              </p>
            </div>

            <div className="col-md-4 col-sm-6 col-xs-12">
              <ul className="social-icons">
                <li>
                  <a
                    className="facebook"
                    target="_blank"
                    href="https://www.facebook.com/Auto-Star-Faisalabad-105859045365498/"
                  >
                    <BsFacebook />
                  </a>
                </li>
                <li>
                  <a className="twitter" href="mailto:asfnaeem@gmail.com">
                    <MdAttachEmail />
                  </a>
                </li>
                <li>
                  <a
                    className="dribbble"
                    href="https://instagram.com/autostarfaisalabad?utm_medium=copy_link"
                    target="_blank"
                  >
                    <FaInstagram />
                  </a>
                </li>
                <li>
                  <a
                    className="dribbble"
                    href="https://twitter.com/AutoFaisalabad"
                    target="_blank"
                  >
                    <FiTwitter />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
