import React from 'react'
import Inventory from './Index'
import { useParams } from 'react-router-dom'

const Edit = () => {
    const {id} = useParams()
  return (
    <div>
        <Inventory id={id} />
    </div>
  )
}

export default Edit