import React from "react";
import Button from "../../../Components/Button/Button";
import Tables from "../../../Components/Table/Table";

const TablePage = () => {
  return (
    <div className="dashTableContainer">
      <div className="dsBtnTb">
        <Button link linkPath="/admin" value="Add New Item" />
      </div>
      <Tables isDashBoard />
    </div>
  );
};

export default TablePage;
