import React from 'react';
import AnimatedLine from '../../Components/AnimatedLine/AnimatedLine';
import Brands from '../../Components/Brands/Brands';
import Card from '../../Components/Card/Card';
import ImageSlider from '../../Components/ImageSlider/ImageSlider';
import Title from '../../Components/SectionTitle/Title';

function Home() {
    return (
        <div >
            <AnimatedLine />
            <ImageSlider />
            <Title value='Featured Products' />
            <Card />
            <Title value='Deals In ASNF' />
            <Brands />
        </div>
    );
}

export default Home;