import React from "react";
import Title from "../../Components/SectionTitle/Title";

const Schedule = () => {
  return (
    <div className="sdlSection">
      <div className="sdlContent">
          <Title value='Time Schedule' />
        <ul className="responsive-table">
          <li className="table-header">
            <div className="col col-1">Days</div>
            <div className="col col-2">Open</div>
            <div className="col col-3">Close</div>
            {/* <div className="col col-4">Payment Status</div> */}
          </li>
          <li className="table-row">
            <div className="col col-1" data-label="Job Id">
              Monday
            </div>
            <div className="col col-2" data-label="Customer Name">
              10:00 am
            </div>
            <div className="col col-3" data-label="Amount">
            8:00 pm
            </div>
          </li>
          <li className="table-row">
            <div className="col col-1" data-label="Job Id">
              Tuesday
            </div>
            <div className="col col-2" data-label="Customer Name">
              10:00 am
            </div>
            <div className="col col-3" data-label="Amount">
            8:00 pm
            </div>
          </li>
          <li className="table-row">
            <div className="col col-1" data-label="Job Id">
              Wednesday
            </div>
            <div className="col col-2" data-label="Customer Name">
              10:00 am
            </div>
            <div className="col col-3" data-label="Amount">
            8:00 pm
            </div>
          </li>
          <li className="table-row">
            <div className="col col-1" data-label="Job Id">
              Thursday
            </div>
            <div className="col col-2" data-label="Customer Name">
              10:00 am
            </div>
            <div className="col col-3" data-label="Amount">
              8:00 pm
            </div>
          </li>
          <li className="table-row">
            <div className="col col-1" data-label="Job Id">
              Friday
            </div>
            <div className="col col-2" data-label="Customer Name">
              Off
            </div>
            <div className="col col-3" data-label="Amount">
              Off
            </div>
          </li>
          <li className="table-row">
            <div className="col col-1" data-label="Job Id">
              Saturday
            </div>
            <div className="col col-2" data-label="Customer Name">
              10:00 am
            </div>
            <div className="col col-3" data-label="Amount">
              8:00 pm
            </div>
          </li>
          <li className="table-row">
            <div className="col col-1" data-label="Job Id">
              Sunday
            </div>
            <div className="col col-2" data-label="Customer Name">
              10:00 am
            </div>
            <div className="col col-3" data-label="Amount">
              8:00 pm
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Schedule;
